import React from "react";
import { Box, Typography, IconButton, useTheme } from "@mui/material";
import Close from "@mui/icons-material/Close";

interface DismissibleBoxProps {
  emailOrPhone: string;
  onBack: () => void;
  sx?: object;
}

const DismissibleBox: React.FC<DismissibleBoxProps> = (props) => {
  const { emailOrPhone, onBack } = props;

  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        ...theme.custom.dismissibleBox,
      }}
    >
      <Typography variant="body1">{emailOrPhone}</Typography>
      <IconButton onClick={onBack}>
        <Close />
      </IconButton>
    </Box>
  );
};

export default DismissibleBox;
