import useAxios from "axios-hooks";

const useLogout = () => {
  const [{ loading: loggingOut, error: logoutError }, logout] = useAxios(
    {
      url: "/logout",
      method: "POST",
    },
    { manual: true },
  );

  const handleLogout = async () => {
    try {
      await logout();
      return { success: true };
    } catch {
      return { success: false, error: logoutError };
    }
  };

  return {
    loggingOut,
    logoutError,
    handleLogout,
  };
};

export default useLogout;
