import axios from "axios";
import { getCSRFTokenIfNeeded, getFrontEndUrl } from "./test-utils";

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.headers.post["Content-Type"] = "application/json";

export const initializeAxios = async () => {
  axios.defaults.baseURL = await getFrontEndUrl();
  await getCSRFTokenIfNeeded();
};

export default axios;
