import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { initializeAxios } from "./utils/axiosConfig";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

initializeAxios()
  .then(() => {
    root.render(<App />);
  })
  .catch((error) => {
    console.error("Failed to initialize Axios:", error);
  });
