import useAxios from "axios-hooks";

const useFetchUser = () => {
  const [{ data, loading, error }] = useAxios({
    url: "/api/v1/user",
    method: "GET",
  });

  return { data, loading, error };
};

export default useFetchUser;
