/*
  This is the parent component for the side navigation in
  the app. Add this parent component, a data structure,
  and an icon map (located in the data folder) to implement
  this menu. NavigationRail is the nav to the left, with
  icon buttons, logo and notifications. NavigationDrawer
  is the slide out drawer and all contents.  

*/
import React, { useState } from "react";
import NavigationRail from "./NavigationRail";
import NavigationDrawer from "./NavigationDrawer";
import { Box, useTheme } from "@mui/material";
import {
  navigationDrawerData,
  DrawerMenuItem,
  DrawerSubItem,
} from "./data/navigationDrawerData";
import { Outlet } from "react-router-dom";

const SideNavigation = () => {
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState(navigationDrawerData[0]);
  const [clickedIndex, setClickedIndex] = useState<number>(0);
  const [selectedSubIndex, setSelectedSubIndex] = useState<number | null>(0);

  /*
    Function to find the ID of the first sub-item of a given menu item,
    this is required as when you click on a rail icon, it needs to highlight
    the first option.

  */
  const findFirstSubItemId = (
    data: DrawerMenuItem[],
    id: number | null,
  ): number | null =>
    data
      .find((item) => item.id === id)
      ?.items?.flat()
      .find((subItem): subItem is DrawerSubItem => !!subItem.id)?.id || null;

  /*
    Handles opening the nav and setting the content

  */
  const handleNavOpen = (index: number | null) => {
    if (index && navigationDrawerData[index].items) {
      setDrawerOpen(true);
      setDrawerContent(navigationDrawerData[index] || navigationDrawerData[0]);
    }
  };

  /*
    This is being called by both the rail and also the drawer. When
    we select an item from the drawer, we want to highlight the
    corresponding rail item. When we click a rail item, we want to
    select the first drawer item in the submenu.

  */
  const handleNavClick = (path: string, id: number | null) => {
    const index = navigationDrawerData.findIndex((item) => item.id === id);
    setClickedIndex(index);

    const selectedSubMenuId = findFirstSubItemId(navigationDrawerData, id);
    setSelectedSubIndex(selectedSubMenuId);
  };

  /*
    This handles the mouse out functionality. If we have clicked an item,
    we don't want the menu to close when you mouse out. If we haven't,
    we want the menu to close.

  */
  const handleDrawerLeave = () => {
    if (
      navigationDrawerData[clickedIndex] &&
      navigationDrawerData[clickedIndex].items
    ) {
      handleNavOpen(clickedIndex);
    } else {
      setDrawerOpen(false);
    }
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <Box sx={theme.custom.navigationDrawer.box}>
        <NavigationDrawer
          open={drawerOpen}
          onLeave={handleDrawerLeave}
          onClose={handleDrawerClose}
          drawerContent={drawerContent}
          onNavClick={handleNavClick}
          controlledActiveIndex={selectedSubIndex}
        />
        <NavigationRail
          onNavOpen={handleNavOpen}
          onNavClick={handleNavClick}
          onClose={handleDrawerClose}
          controlledActiveIndex={clickedIndex}
        />
      </Box>
      <Outlet context={{ drawerOpen }} />
    </>
  );
};

export default SideNavigation;
