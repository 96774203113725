import useAxios from "axios-hooks";

const useSendOTPCode = () => {
  const [{ error: linkError, data: linkData }, sendOTPCode] = useAxios(
    {
      url: "/request-otp",
      method: "POST",
    },
    { manual: true },
  );

  const handleSendOTPCode = async (emailOrPhone: string) => {
    try {
      await sendOTPCode({ data: JSON.stringify({ email: emailOrPhone }) });
      console.debug("Sent login link successfully:", linkData);
      return { success: true, data: linkData };
    } catch (error) {
      console.error("Error sending login link:", error);
      return { success: false, error: linkError };
    }
  };

  return { handleSendOTPCode, linkError, linkData };
};

export default useSendOTPCode;
