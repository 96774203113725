import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../hooks/useRedux";
import { RootState } from "../../store/store";
import { setAuthState } from "../../store/slices/authSlice";
import useFetchUser from "../../hooks/useFetchUser";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

interface AuthWrapperProps {
  children: ReactNode;
  isProtected: boolean;
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children, isProtected }) => {
  const theme = useTheme();
  const isAuthenticated = useAppSelector(
    (state: RootState) => state.auth.isAuthenticated,
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [displayContent, setDisplayContent] = useState<boolean>(false);
  const { data, loading } = useFetchUser();

  const { box, circularProgress } = theme.custom.authWrapper;

  useEffect(() => {
    if (!loading) {
      dispatch(setAuthState(!!data));
      setDisplayContent(true);
    }
  }, [data, loading]);

  useEffect(() => {
    if (!displayContent) return;
    if (isProtected && !isAuthenticated) {
      navigate("/");
    } else if (!isProtected && isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isProtected, isAuthenticated, displayContent]);

  if (!displayContent) {
    return (
      <Box sx={box}>
        <CircularProgress sx={circularProgress} />
      </Box>
    );
  }

  return <>{children}</>;
};

export default AuthWrapper;
