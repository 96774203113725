import React, { ReactNode } from "react";
import { Box, Modal } from "@mui/material";

interface ModalContainerProps {
  isOpen: boolean;
  sx?: object;
  children?: ReactNode;
}

const ModalContainer: React.FC<ModalContainerProps> = (props) => {
  const { isOpen, sx = {}, children } = props;
  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          ...sx,
        }}
      >
        {children}
      </Box>
    </Modal>
  );
};

export default ModalContainer;
