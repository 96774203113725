import React from "react";

interface LoginWaveProps {
  width?: number;
  height?: number;
}

const LoginWave: React.FC<LoginWaveProps> = (props) => {
  const { width = 693, height = 693 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 1176 1185"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.6"
        d="M1445.72 334.681L1618.21 829.748C1701.91 1070.09 1774.32 1346.68 1136.04 1497.26L438.071 1662.78C-387.238 1857.49 186.735 979.293 298.572 750.642L528.924 279.665C723.19 -117.533 1300.26 -82.9492 1445.72 334.602V334.681Z"
        fill="#E4E3F6"
      />
    </svg>
  );
};

export default LoginWave;
