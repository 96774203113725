import React from "react";

interface FacebookIconProps {
  width?: number;
  height?: number;
}

const FacebookIcon: React.FC<FacebookIconProps> = (props) => {
  const { width = 28, height = 28 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.7342 15.0067C28.7342 7.18142 22.3904 0.837646 14.5651 0.837646C6.73977 0.837646 0.395996 7.18131 0.395996 15.0067C0.395996 22.0789 5.57746 27.9408 12.3512 29.0037V19.1025H8.75355V15.0067H12.3512V11.8851C12.3512 8.33399 14.4666 6.37245 17.703 6.37245C19.2533 6.37245 20.8748 6.64919 20.8748 6.64919V10.1361H19.0881C17.3278 10.1361 16.779 11.2284 16.779 12.3489V15.0067H20.7087L20.0805 19.1025H16.779V29.0037C23.5527 27.9408 28.7342 22.079 28.7342 15.0067Z"
        fill="#0F0D21"
      />
      <path
        d="M20.0804 19.1025L20.7086 15.0068H16.7789V12.349C16.7789 11.2283 17.3278 10.1362 19.088 10.1362H20.8746V6.64924C20.8746 6.64924 19.2532 6.3725 17.7029 6.3725C14.4664 6.3725 12.351 8.33403 12.351 11.8852V15.0068H8.75342V19.1025H12.351V29.0038C13.0834 29.1185 13.8236 29.1761 14.565 29.1759C15.3063 29.1761 16.0465 29.1186 16.7789 29.0038V19.1025H20.0804Z"
        fill="white"
      />
    </svg>
  );
};

export default FacebookIcon;
