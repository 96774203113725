import HomeIcon from "@mui/icons-material/Home";
import LayersIcon from "@mui/icons-material/Layers";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import TodayIcon from "@mui/icons-material/Today";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SettingsIcon from "@mui/icons-material/Settings";
import React from "react";

/*
  Icons and titles that are displayed on the NavigationRail.

*/
const iconMap: { [key: string]: React.ReactNode } = {
  Dashboard: <HomeIcon fontSize="small" />,
  Enquiries: <LayersIcon fontSize="small" />,
  Products: <ShoppingCartIcon fontSize="small" />,
  Users: <PersonIcon fontSize="small" />,
  Accounts: <SwitchAccountIcon fontSize="small" />,
  Files: <FileCopyIcon fontSize="small" />,
  Renewals: <TodayIcon fontSize="small" />,
  Lenders: <AccountBalanceIcon fontSize="small" />,
  Reporting: <AssessmentIcon fontSize="small" />,
  System: <SettingsIcon fontSize="small" />,
};

export default iconMap;
