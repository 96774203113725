import React, { useState, MouseEvent, useEffect } from "react";
import {
  Box,
  IconButton,
  Typography,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ProvideLogo from "../Icons/ProvideLogo/ProvideLogo";
import iconMap from "./data/navigationRailIconMap";
import {
  navigationDrawerData,
  DrawerMenuItem,
} from "./data/navigationDrawerData";
import useLogout from "../../hooks/useLogout";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useRedux";
import { logout } from "../../store/slices/authSlice";

interface NavigationRailProps {
  onNavOpen: (index: number, hasSubItems?: boolean) => void;
  onNavClick: (path: string, index: number) => void;
  onClose: () => void;
  controlledActiveIndex?: number | null;
}

const NavigationRail: React.FC<NavigationRailProps> = ({
  onNavOpen,
  onClose,
  onNavClick,
  controlledActiveIndex,
}) => {
  const theme = useTheme();
  const { handleLogout } = useLogout();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    container,
    logoBox,
    listItem,
    iconButton,
    text,
    bottomSection,
    notificationIcon,
    avatar,
    logoMarginBottom,
    iconButtonGray,
    activeBackgroundColor,
    inactiveBackgroundColor,
  } = theme.custom.navigationRail;

  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  /*
    Effect to sync the active index with the controlled active index from parent.
    This is to highlight a rail icon if a drawer icon is clicked.

  */
  useEffect(() => {
    if (controlledActiveIndex !== undefined && controlledActiveIndex !== null) {
      setActiveIndex(controlledActiveIndex);
    }
  }, [controlledActiveIndex]);

  /*
    Handle navigation open and determine if the item has sub-items

  */
  const handleNavOpen = (index: number) => {
    const selectedItem = navigationDrawerData[index];
    const hasSubItems = !!selectedItem.items;

    setActiveIndex(index);
    onNavOpen(index, hasSubItems);

    if (!hasSubItems) onClose();
  };

  /*
    Handle click event on the avatar to open the logout menu

  */
  const handleAvatarClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /*
    Close the avatar menu when invoked

  */
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /*
    Handle logout functionality

  */
  const handleLogoutClick = async () => {
    const result = await handleLogout();

    if (result.success) {
      dispatch(logout());
      handleMenuClose();
      navigate("/");
    }
  };

  const defaultTheme = JSON.stringify({
    name: "Provide",
    primary_color: null,
    secondary_color: null,
    tertiary_color: null,
    logo: null,
    t_name: "Provide Finance",
  });
  const companyTheme = JSON.parse(
    localStorage.getItem("PF-CONF") || defaultTheme,
  );

  return (
    <Box sx={container}>
      <Box sx={logoBox}>
        <Box sx={logoMarginBottom}>
          {companyTheme.logo ? (
            <img
              src={companyTheme.logo}
              alt={companyTheme.name}
              width={212}
              height={44}
            />
          ) : (
            <ProvideLogo width={32} height={32} />
          )}
        </Box>
        {navigationDrawerData.map((item: DrawerMenuItem, index: number) => (
          <Box
            key={item.id}
            sx={listItem}
            onClick={() => {
              handleNavOpen(index);
              onNavClick(item.path!, item.id);
            }}
            onMouseOver={() => onNavOpen(index)}
          >
            <IconButton
              sx={{
                ...iconButton,
                backgroundColor:
                  activeIndex === index
                    ? activeBackgroundColor
                    : inactiveBackgroundColor,
              }}
            >
              {iconMap[item.title]}
            </IconButton>
            <Typography variant="loginParagraphText" sx={text}>
              {item.title}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box sx={bottomSection}>
        <Box sx={notificationIcon}>
          <IconButton sx={iconButtonGray}>
            <NotificationsIcon fontSize="medium" />
          </IconButton>
        </Box>
        <Box>
          <Avatar onClick={handleAvatarClick} sx={avatar}>
            AM
          </Avatar>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export default NavigationRail;
