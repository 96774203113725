import React, { useState } from "react";
import {
  Typography,
  FormControl,
  OutlinedInput,
  FormHelperText,
  Box,
} from "@mui/material";
import FacebookIcon from "../Icons/FacebookIcon/FacebookIcon";
import GoogleIcon from "../Icons/GoogleIcon/GoogleIcon";
import AppleIcon from "../Icons/AppleIcon/AppleIcon";
import ProvideButton from "../Buttons/ProvideButton";
import useValidateInput from "../../hooks/useValidateInput";
import { useTheme } from "@mui/material/styles";

interface EmailPhoneInputProps {
  emailOrPhone: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onContinue: () => void;
}

const socialButtons = [
  {
    icon: <FacebookIcon />,
    label: "CONTINUE WITH FACEBOOK",
    onClick: () => console.debug("Login Facebook button"),
  },
  {
    icon: <GoogleIcon />,
    label: "CONTINUE WITH GOOGLE",
    onClick: () => console.debug("Login Google button"),
  },
  {
    icon: <AppleIcon />,
    label: "CONTINUE WITH APPLE",
    onClick: () => console.debug("Login Apple button"),
  },
];

const EmailPhoneInput: React.FC<EmailPhoneInputProps> = (props) => {
  const theme = useTheme();
  const {
    provideButtonOutlined,
    iconBox,
    typography,
    formControl,
    outlinedInput,
    continueButton,
  } = theme.custom.emailPhoneInput;

  const { emailOrPhone, onChange, onContinue } = props;
  const { errors, validateEmailOrPhone } = useValidateInput();

  const [isInputValid, setIsInputValid] = useState<boolean>(false);

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const validationResult = validateEmailOrPhone(evt.target.value);
    setIsInputValid(validationResult);
    onChange(evt);
  };

  return (
    <>
      {socialButtons.map(({ icon, label, onClick }, index) => (
        <ProvideButton
          key={index}
          onClick={onClick}
          variant="outlined"
          sx={provideButtonOutlined}
        >
          <Box sx={iconBox}>{icon}</Box>
          {label}
        </ProvideButton>
      ))}

      <Typography variant="loginH4Text" component="p" sx={typography}>
        Or Email / Phone
      </Typography>

      <FormControl fullWidth variant="outlined" sx={formControl}>
        <OutlinedInput
          id="outlined-email-phone"
          placeholder="your@email.com / +447739477105"
          value={emailOrPhone}
          onChange={handleChange}
          error={errors.general.error}
          sx={outlinedInput}
        />
        {errors.general.error && (
          <FormHelperText error>{errors.general.helperText}</FormHelperText>
        )}
      </FormControl>

      <ProvideButton
        onClick={onContinue}
        disabled={!isInputValid}
        variant="contained"
        sx={continueButton}
      >
        CONTINUE
      </ProvideButton>
    </>
  );
};

export default EmailPhoneInput;
