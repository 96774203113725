import React from "react";
import { Button, SxProps, Theme } from "@mui/material";
import IconRight from "@mui/icons-material/ArrowForwardIosRounded";

interface ProvideButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  variant?: "outlined" | "contained";
  sx?: SxProps<Theme>;
}

const ProvideButton: React.FC<ProvideButtonProps> = (props) => {
  const { children } = props;

  return (
    <Button {...props} endIcon={<IconRight sx={{ height: "17px" }} />}>
      {children}
    </Button>
  );
};

export default ProvideButton;
